<template>
  <v-row v-if="serverData" class="mx-5">
    <!-- organization -->
    <v-col v-if="serverData.organizations" sm="12">
      <FieldContainer
        :label="$t('organization')"
        :divider="true"
        :disabled="false"
      >
        <v-autocomplete
          class="rounded-lg h-50px"
          v-model="formData.organization_id"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :items="serverData.organizations"
          :error-messages="organziationErrors"
          @input="$v.formData.organization_id.$touch()"
          @blur="$v.formData.organization_id.$touch()"
        ></v-autocomplete>
      </FieldContainer>
    </v-col>
    <!-- cusomer -->
    <v-col sm="12">
      <FieldContainer :label="$t('customer')" :divider="true" :disabled="false">
        <v-autocomplete
          class="rounded-lg h-50px"
          v-model="formData.customer_id"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :items="serverData.clients"
          :error-messages="customerErrors"
          @input="$v.formData.customer_id.$touch()"
          @blur="$v.formData.customer_id.$touch()"
        ></v-autocomplete>
      </FieldContainer>
    </v-col>
    <!-- movement type -->
    <v-col sm="12">
      <FieldContainer
        :label="$t('movement_type')"
        :divider="false"
        :disabled="false"
      >
        <v-autocomplete
          class="rounded-lg h-50px"
          v-model="formData.movement_type_id"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :items="serverData.movement_types"
          :error-messages="movementTypeErrors"
          @input="$v.formData.movement_type_id.$touch()"
          @blur="$v.formData.movement_type_id.$touch()"
        ></v-autocomplete>
      </FieldContainer>
    </v-col>

    <v-col sm="12" class="d-flex justify-content-end my-5 px-0">
      <button type="button" class="btn btn-info px-5 py-3 ls1" @click="submit">
        Continue
      </button>
    </v-col>

    <!--  -->
    <ActionsComponent
      ref="actionsComponent"
      :fieldsData="fieldsData"
      :basicData="formData"
      :clearBasicFields="clearFields"
      :movement_type_id="formData.movement_type_id"
    />
    <!--  -->
  </v-row>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

import { required, requiredIf } from "vuelidate/lib/validators";

import validationMixin from "@/own/mixins/validationMixin.js";
import FieldContainer from "@/own/components/FieldContainer.vue";
import ActionsComponent from "@/own/components/warehouseManagement/StandardPackageMovement/ActionsComponent.vue";

export default {
  name: "StandardPackageMovement",
  mixins: [validationMixin],
  components: { FieldContainer, ActionsComponent },
  validations: {
    formData: {
      organization_id: {
        required: requiredIf(function () {
          return this.serverData.hasOwnProperty("organizations");
        }),
      },
      customer_id: { required },
      movement_type_id: { required },
    },
  },
  data: () => ({
    formData: {
      organization_id: null,
      customer_id: null,
      movement_type_id: null,
    },
    fieldsData: null,
  }),
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store.commit(SET_PAGE_LOADING, true);

      const payload = {
        ...this.formData,
        warehouse_id: this.selectedWarehouse,
      };

      ApiService.post(
        `warehouse_management/custom/package_movements/get-scanning-fields`,
        payload
      )
        .then(({ data }) => {
          this.fieldsData = data;
          setTimeout(() => {
            this.$refs.actionsComponent.toggleModal();
          }, 500);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    clearFields() {
      this.formData = {
        organization_id: null,
        customer_id: null,
        movement_type_id: null,
      };
    },
  },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    serverData: function () {
      return this.$store.getters.getSTANDARDPACKAGEMOVEMENTCustomersData;
    },
    organziationErrors() {
      return this.handleFormValidation("organization_id", this);
    },
    customerErrors() {
      return this.handleFormValidation("customer_id", this);
    },
    movementTypeErrors() {
      return this.handleFormValidation("movement_type_id", this);
    },
  },
};
</script>
